import React from 'react';

import Footer from './components/Footer';
import Header from './components/Headers';
import './styles/layout.scss';

export default function Styles({ children }: any) {
  return (
    <div className={'layout'}>
      <Header />
      <div className={'layout_components'}>{children}</div>
      <Footer />
    </div>
  );
}
