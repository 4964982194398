import { Auth } from '@aws-amplify/auth';

import { COGNITO_REGION, COGNITO_POOL_ID, COGNITO_CLIENT_ID } from 'utils/config';

Auth.configure({
  region: COGNITO_REGION,
  userPoolId: COGNITO_POOL_ID,
  userPoolWebClientId: COGNITO_CLIENT_ID,
  mandatorySignIn: true,
  authenticationFlowType: 'USER_SRP_AUTH',
  endpoint: `https://cognito-idp-fips.${COGNITO_REGION}.amazonaws.com`,
});

export default Auth;
