import { Dispatch } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios';

import { getUserProfile as getUserProfileRequest } from 'services/auth';
import Auth from 'utils/cognito';
import { ALERT_TYPES, ALLOWED_ROLES } from 'utils/constants';
import messages from 'utils/notificationMessages';
import { AlertTypes } from 'utils/types';

import { AuthUserProfile } from './types';

// ++ auto-logout
let autoLogoutTimeout: any = null;

export const cancelAutoLogoutCheck = (): void => {
  if (autoLogoutTimeout !== null) {
    clearTimeout(autoLogoutTimeout);
    autoLogoutTimeout = null;
  }
};

const autoLogoutCheck = async (logoutCallback: () => Promise<void>): Promise<void> => {
  let isValidSession = false;

  try {
    const session = await Auth.currentSession();
    isValidSession = session.isValid();
  } catch (e) {
    console.log(e);
  }

  if (!isValidSession) {
    console.log('AUTOLOGOUT: session expired');
    await logoutCallback();
    return;
  }

  if (autoLogoutTimeout === null) {
    autoLogoutTimeout = setTimeout(async () => {
      autoLogoutTimeout = null;
      await autoLogoutCheck(logoutCallback);
    }, 5000);
  }
};
// -- auto-logout

export const logout = async (
  message: string | null,
  dispatch: Dispatch<any>,
  setNotification?: (m: string, type: AlertTypes) => void
): Promise<void> => {
  cancelAutoLogoutCheck();

  dispatch({ loading: true });

  try {
    await Auth.signOut({ global: true });
  } catch (e) {
    console.log(e);
  }

  localStorage.clear();

  dispatch({ profile: null, loading: false, error: '' });

  if (message && setNotification) {
    setNotification(message, ALERT_TYPES.INFO);
  }
};

const getUseProfile = async (
  dispatch: Dispatch<any>,
  setNotification: (m: string, type: AlertTypes) => void
): Promise<{ apiOnline: boolean; user: AuthUserProfile | null }> => {
  try {
    let userDetails = await getUserProfileRequest();

    if (userDetails === null || !Object.keys(ALLOWED_ROLES).includes(userDetails.role)) {
      // await logout('Access denied', dispatch, setNotification);
      userDetails = null; // no access for this user
    }

    return { apiOnline: true, user: userDetails };
  } catch (e: AxiosError | any) {
    console.log(e);
    if (!e.response) {
      setNotification(messages.errors.API_OFFLINE, 'error');
      return { apiOnline: false, user: null }; // all call failed
    }

    return { apiOnline: true, user: null }; // api checked by no access for this user
  }
};

export const loadLocalUser = async (
  dispatch: Dispatch<any>,
  setNotification: (m: string, type: AlertTypes) => void
): Promise<{ isAuthenticated: boolean; proceedToNextStep: boolean }> => {
  let localUserEmail = '';

  try {
    const result = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    localUserEmail = result.attributes.email;
    console.log('Local authenticated user:', localUserEmail);
  } catch (e) {
    console.log('User is not logged in', e);
    localUserEmail = '';
  }

  if (localUserEmail === '') {
    // user isn't logged in
    dispatch({
      profile: null,
      populated: true,
      loading: false,
      action: null,
      error: '',
    });

    return { isAuthenticated: false, proceedToNextStep: true };
  }

  dispatch({ loading: true });

  const { apiOnline, user: userProfile } = await getUseProfile(dispatch, setNotification);

  const updatePayload: any = {
    profile: userProfile,
    populated: true,
    loading: false,
  };

  if (apiOnline) {
    updatePayload.action = null;
    updatePayload.error = '';
  } else {
    updatePayload.error = messages.errors.API_OFFLINE;
  }

  dispatch(updatePayload);

  const isAuthenticated = userProfile !== null;

  if (isAuthenticated) {
    autoLogoutCheck(async () => await logout('Your session expired', dispatch, setNotification));
  }

  return { isAuthenticated, proceedToNextStep: apiOnline };
};
