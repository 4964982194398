import React, { createContext, useReducer } from 'react';

import * as testResultsApi from 'services/testResults';
import { serializeApiErrors } from 'utils/errors';
import { loadAllPages, makeReducer } from 'utils/functions';
import { TestResult } from 'utils/types';

import { State, Context, TestResultOptions } from './types';

const getInitialState = (): State => ({
  testResults: [],
  testResult: null,
  error: null,
  loading: false,
});

const TestResultsContext = createContext<Context>({
  ...getInitialState(),
  getTestResults: async () => {},
  getTestResult: async () => {},
  resetTestResults: () => {},
});

const TestResultsProvider = (props: any) => {
  const [state, setState] = useReducer(makeReducer<State>(), getInitialState());

  const getTestResults = async (
    params: Record<string, string>,
    options: TestResultOptions = {},
    callback?: (testResults: TestResult[] | null) => Promise<void>
  ): Promise<void> => {
    try {
      setState({ loading: true, error: null });

      type PayloadType = Parameters<typeof testResultsApi.getResults>[0];
      const results = await loadAllPages<TestResult, PayloadType>(testResultsApi.getResults, params, {
        pageLength: 100,
      });

      if (options.appendState) {
        const existingIds = state.testResults.map((testResult) => testResult.id);
        const appendedTestResults = state.testResults.concat(
          results.reduce((acc: TestResult[], curr: TestResult) => {
            if (!existingIds.includes(curr.id)) {
              acc.push(curr);
            }
            return acc;
          }, [])
        );
        setState({
          testResults: appendedTestResults,
        });
      } else {
        setState({
          testResults: results,
        });
      }

      if (callback) {
        await callback(results);
      }
    } catch (e) {
      setState({
        error: serializeApiErrors(e),
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState({
        loading: false,
      });
    }
  };

  const getTestResult = async (id: string, callback: any): Promise<void> => {
    try {
      setState({ loading: true, error: null });

      const result: TestResult = await testResultsApi.getTestResult(id);

      setState({
        loading: false,
        testResult: result,
      });

      if (callback) {
        await callback();
      }
    } catch (e) {
      setState({
        loading: false,
        error: serializeApiErrors(e),
      });
    }
  };

  const resetTestResults = (): void => setState(getInitialState());

  return (
    <TestResultsContext.Provider
      value={{
        testResults: state.testResults,
        testResult: state.testResult,
        loading: state.loading,
        error: state.error,
        getTestResults,
        getTestResult,
        resetTestResults,
      }}
      {...props}
    />
  );
};

const useTestResults = (): Context => React.useContext(TestResultsContext);

export { TestResultsProvider, useTestResults };
