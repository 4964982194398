import React, { createContext, FC, useReducer } from 'react';

import * as projectMetricsApi from 'services/projectMetrics';
import { serializeApiErrors } from 'utils/errors';
import { makeReducer } from 'utils/functions';

import { GetProjectMetricsFunction, State, Context, ProjectMetrics } from './types';

const getInitialState = (): State => ({
  projectMetrics: null,
  loading: false,
  error: null,
});

const ProjectMetricsContext = createContext<Context>({
  ...getInitialState(),
  getProjectMetrics: async () => {},
  resetProjectMetrics: () => {},
});

const formatMetrics = (metrics: any): ProjectMetrics => {
  return {
    atLab: parseInt(metrics.devicesWhereStatusIsAtLabCount, 10),
    atPatient: parseInt(metrics.devicesWhereStatusIsAtPatientCount, 10),
    awaitingPickup: parseInt(metrics.devicesWhereStatusIsAwaitingPickupCount, 10),
    inStock: parseInt(metrics.devicesWhereStatusIsInStockCount, 10),
    inTransitToLab: parseInt(metrics.devicesWhereStatusIsInTransitToLabCount, 10),
    inTransitToPatient: parseInt(metrics.devicesWhereStatusIsInTransitToPatientCount, 10),
    readyToShip: parseInt(metrics.devicesWhereStatusIsReadyToShipCount, 10),
    resultsReady: parseInt(metrics.devicesWhereStatusIsResultsReadyCount, 10),
    replaced: parseInt(metrics.devicesWhereStatusIsReplacedCount, 10),
    deviceCount: parseInt(metrics.deviceCount, 10),
    createdAt: metrics.createdAt,
    updatedAt: metrics.updatedAt,
  };
};

const ProjectMetricsProvider: FC = (props: any) => {
  const [state, setState] = useReducer(makeReducer<State>(), getInitialState());

  const getProjectMetrics: GetProjectMetricsFunction = async (params) => {
    setState({ loading: true, error: null });

    try {
      const data = await projectMetricsApi.getProjectMetrics(params);
      const { results } = data;

      setState({ projectMetrics: formatMetrics(results[0]) });
    } catch (e) {
      setState({
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
      });
    }

    setState({ loading: false });
  };

  const resetProjectMetrics = (): void => {
    setState({ ...getInitialState() });
  };

  return (
    <ProjectMetricsContext.Provider
      value={{
        projectMetrics: state.projectMetrics,
        loading: state.loading,
        error: state.error,
        getProjectMetrics,
        resetProjectMetrics,
      }}
      {...props}
    />
  );
};

const useProjectMetrics = () => React.useContext(ProjectMetricsContext);

export { ProjectMetricsProvider, useProjectMetrics };
