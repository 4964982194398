import React, { createContext, useContext, useEffect, useReducer } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AuthError } from '@aws-amplify/auth/lib-esm/Errors';
import { AuthErrorTypes } from '@aws-amplify/auth/lib-esm/types';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import Loading from 'components/Loading';
import Auth from 'utils/cognito';
import { AWS_CHALLENGE_NAMES, ALERT_TYPES } from 'utils/constants';
import { notification, makeReducer } from 'utils/functions';
import messages from 'utils/notificationMessages';
import { ROUTES as routes } from 'utils/routes';
import { AlertTypes } from 'utils/types';

import { logout, loadLocalUser } from './functions';
import { AuthContext as AuthContextType, AuthState } from './types';

// https://docs.amplify.aws/lib/auth/mfa/q/platform/js

const AuthContext = createContext<AuthContextType>({
  profile: null,
  action: null,
  login: async () => {},
  logout: async () => {},
  forgotPassword: async () => {},
  confirmPassword: async () => {},
  loading: false,
  error: '',
});

const AuthProvider = (props: any) => {
  const history = useHistory();

  const {
    location: { pathname },
  } = history;

  const [state, setState] = useReducer(makeReducer<AuthState>(), {
    action: null,
    profile: null,
    error: '',
    loading: false,
    populated: false,
  });

  useEffect(() => {
    state.error && setState({ error: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function setNotification(m: string, t?: AlertTypes, duration?: number | null, key?: string) {
    notification(m, enqueueSnackbar, t, duration, key);
  }

  const handleLoginResponse = async (user: any, email: string): Promise<void> => {
    if (user.getSignInUserSession() === null && typeof user.challengeName === 'string') {
      if (user.challengeName === AWS_CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED) {
        const callback = async (newPassword: string): Promise<void> => {
          setState({ loading: true });

          try {
            const result = await Auth.completeNewPassword(user, newPassword, null);
            await handleLoginResponse(result, email);
          } catch (e: AuthError | any) {
            let errorMessage: string;
            if (typeof e?.code === 'string' && e.code.toLowerCase() === AuthErrorTypes.NetworkError.toLowerCase()) {
              errorMessage = messages.errors.NETWORK_ERROR;
            } else {
              errorMessage = e.message;
            }

            setNotification(errorMessage);

            setState({
              loading: false,
              error: errorMessage,
            });
          }
        };

        setState({
          action: { type: 'setPassword', callback },
          loading: false,
        });
      } else if (user.challengeName === AWS_CHALLENGE_NAMES.MFA_SETUP) {
        const callback = async (code: string): Promise<void> => {
          setState({ loading: true });

          try {
            await Auth.verifyTotpToken(user, code);
            await Auth.setPreferredMFA(user, 'TOTP');
            await handleLoginResponse(user, email);
          } catch (e: AuthError | any) {
            let errorMessage: string;

            if (typeof e?.code === 'string' && e.code === AuthErrorTypes.NetworkError.toLowerCase()) {
              errorMessage = messages.errors.NETWORK_ERROR;
            } else if (e?.code === 'EnableSoftwareTokenMFAException') {
              errorMessage = messages.errors.INCORRECT_MFA;
            } else {
              errorMessage = e.message;
            }

            setNotification(errorMessage);

            setState({
              loading: false,
              error: errorMessage,
            });
          }
        };

        const code = await Auth.setupTOTP(user);

        setState({
          action: { type: 'setupMfa', callback, code, email },
          loading: false,
        });
      } else if (user.challengeName === AWS_CHALLENGE_NAMES.SOFTWARE_TOKEN_MFA) {
        const callback = async (code: string): Promise<void> => {
          setState({ loading: true });

          try {
            const result = await Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA');
            await handleLoginResponse(result, email);
          } catch (e: AuthError | any) {
            let errorMessage: string;
            if (typeof e?.code === 'string' && e.code.toLowerCase() === AuthErrorTypes.NetworkError) {
              errorMessage = messages.errors.NETWORK_ERROR;
            } else if (e.code === 'CodeMismatchException') {
              errorMessage = messages.errors.INCORRECT_MFA;
            } else if (e.code === 'NotAuthorizedException') {
              setState({
                loading: false,
                error: '',
              });

              setNotification(
                (
                  <>
                    Authentication error, please
                    <Button
                      style={{ marginLeft: '10px' }}
                      variant="contained"
                      size="small"
                      onClick={async () => {
                        await logout(null, setState);
                        setState({ action: null, profile: null, error: '', loading: false, populated: true });
                        closeSnackbar('mfaErrorBar');
                      }}
                    >
                      restart session
                    </Button>
                  </>
                ) as any,
                'error',
                null,
                'mfaErrorBar'
              );

              return;
            } else {
              errorMessage = e.message;
            }

            setNotification(errorMessage);

            setState({
              loading: false,
              error: errorMessage,
            });
          }
        };

        setState({
          action: { type: 'enterCode', callback },
          loading: false,
        });
      }
    } else {
      const { isAuthenticated, proceedToNextStep } = await loadLocalUser(setState, setNotification);

      if (!proceedToNextStep) {
        return;
      }

      if (isAuthenticated) {
        history.push(routes.PROJECTS.PATH);
      } else {
        history.push(routes.LOGIN.PATH);
      }

      setState({
        action: null,
        loading: false,
        error: '',
      });
    }
  };

  const login = async (email: string, password: string): Promise<void> => {
    setState({ loading: true });

    try {
      const user = await Auth.signIn(email, password);
      await handleLoginResponse(user, email);
    } catch (e: AuthError | any) {
      if (typeof e?.code === 'string' && e.code.toLowerCase() === AuthErrorTypes.NetworkError.toLowerCase()) {
        setNotification(messages.errors.NETWORK_ERROR);
      } else if (e.code === 'PasswordResetRequiredException') {
        setNotification('Password reset required', ALERT_TYPES.WARNING);
        setState({ loading: false });
        history.push({
          pathname: routes.CONFIRM_PASSWORD.PATH,
          state: { email },
        });
        return;
      } else {
        setNotification(e.message);
      }

      setState({
        loading: false,
        error: e.message,
      });
    }
  };

  const forgotPassword = async (email: string): Promise<void> => {
    setState({ loading: true });

    try {
      await Auth.forgotPassword(email);

      history.push({
        pathname: routes.LOGIN.PATH,
        state: { email },
      });

      setNotification(messages.info.CHECK_EMAIL, ALERT_TYPES.SUCCESS);
      setState({ loading: false });
    } catch (e: AuthError | any) {
      let errorMessage: string;
      if (typeof e?.code === 'string' && e.code.toLowerCase() === AuthErrorTypes.NetworkError.toLowerCase()) {
        errorMessage = messages.errors.NETWORK_ERROR;
      } else {
        errorMessage = e.message;
      }

      setNotification(errorMessage);

      setState({
        loading: false,
        error: errorMessage,
      });
    }
  };

  const confirmPassword = async (password: string, email: string, code: string) => {
    setState({ loading: true });

    try {
      await Auth.forgotPasswordSubmit(email, code, password);

      history.push(routes.LOGIN.PATH);
      setNotification(messages.success.NEW_PASSWORD_SET, ALERT_TYPES.SUCCESS);
      setState({ loading: false });
    } catch (e: AuthError | any) {
      let errorMessage: string;

      if (typeof e?.code === 'string' && e.code.toLower() === AuthErrorTypes.NetworkError.toLowerCase()) {
        errorMessage = messages.errors.NETWORK_ERROR;
      } else {
        errorMessage = e?.response?.data?.error || e.message;
      }

      setNotification(errorMessage);

      setState({
        loading: false,
        error: errorMessage,
      });
    }
  };

  useEffect(() => {
    loadLocalUser(setState, setNotification);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { children, ...otherProps } = props;

  return (
    <AuthContext.Provider
      value={{
        profile: state.profile,
        action: state.action,
        login,
        logout: (message: string | null) => logout(message, setState, setNotification),
        forgotPassword,
        confirmPassword,
        loading: state.loading,
        error: state.error,
      }}
      {...otherProps}
    >
      {state.populated ? children : <Loading />}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => useContext(AuthContext);

export { AuthProvider, useAuth };
