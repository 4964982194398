import { httpClient } from 'services/index';

export interface CreatePayload {
  projectId: string;
  fileName: string;
}

export const createPatientImport = (data: CreatePayload): Promise<any> => {
  return httpClient.post('/patientImportsV2', data).then((_) => _.data.results);
};

export const getPatientImport = (importId: string, hydration: string[] = []): Promise<any> => {
  const headers: Record<string, string> = {};

  if (hydration.length > 0) {
    headers['tasso-hydration'] = hydration.join(',');
  }

  return httpClient.get(`/patientImportsV2/${importId}`, { headers }).then((_) => _.data.results);
};
