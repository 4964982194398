import { httpClient } from 'services';

export const resetPassword = (data: any): Promise<void> =>
  httpClient.post('/request-password', data).then((_) => _.data);

export const confirmPassword = (data: any): Promise<void> =>
  httpClient.post('/confirm-password', data).then((_) => _.data);

export const getUserProfile = (): Promise<any> =>
  httpClient.get('/users/me').then((_) => (_.data && _.data.results ? _.data.results : null));
