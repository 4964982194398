import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export const getDeviceEvents = (projectId: string, paging: PagingRequest): Promise<any> =>
  httpClient
    .get('/deviceEvents', {
      params: { projectIds: projectId, ...paging },
    })
    .then((_) => _.data);

export const getDeviceEventsForDevice = (deviceIds: string[], paging: PagingRequest): Promise<any> =>
  httpClient
    .get('/deviceEvents', {
      params: { deviceIds: deviceIds.join(','), ...paging },
    })
    .then((_) => _.data);
