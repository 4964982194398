import * as _I from 'utils/types';

export const ROUTES: _I.RoutePageMap = {
  CONFIRM_PASSWORD: {
    PAGE: () => import('pages/Login'),
    PATH: '/confirm',
    PROTECTED: false,
  },
  LOGIN: {
    PAGE: () => import('pages/Login'),
    PATH: '/login',
    PROTECTED: false,
  },
  LOGOUT: {
    PAGE: () => import('pages/Projects/AllProjects'),
    PATH: '/logout',
    PROTECTED: true,
  },
  PROJECT: {
    PAGE: () => import('pages/Projects/Project'),
    PATH: '/projects/:projectId/(kits)?',
    PROTECTED: true,
  },
  PATIENT_IMPORT: {
    PAGE: () => import('pages/Projects/PatientImport/PatientImportPage'),
    PATH: '/projects/:projectId/import',
    PROTECTED: true,
  },
  PROJECTS: {
    PAGE: () => import('pages/Projects/AllProjects'),
    PATH: '/projects',
    PROTECTED: true,
  },
  RESET_PASSWORD: {
    PAGE: () => import('pages/Login'),
    PATH: '/reset',
    PROTECTED: false,
  },
  SETTINGS: {
    PAGE: () => import('pages/Projects/AllProjects'),
    PATH: '/settings',
    PROTECTED: true,
  },
};
