export const serializeApiErrors = (error: any): string => {
  if (!error) {
    return '';
  }

  const { response } = error;

  if (response) {
    if (
      response.data &&
      response.data.issues &&
      response.data.issues.errors &&
      response.data.issues.errors.length > 0
    ) {
      return response.data.issues.errors.map((err: any) => err.message).join('\n');
    } else if (response.data && response.data.message) {
      return response.data.message;
    }
    return 'Error';
  }

  return error.message || 'Error';
};
