import { userInfo } from './constants';

const messages = {
  info: {
    CHECK_EMAIL: 'Check your mail for further instructions!',
    // NEW_PASSWORD: `Please enter ${userInfo.NEW_PASSWORD}!`,
    // OLD_PASSWORD: `Please enter ${userInfo.OLD_PASSWORD}!`,
    // PASSCODE: 'Please enter "Pass Code"!',
    // PASSWORD: `Please enter ${userInfo.PASSWORD}`,
    // USER_NAME: `Please enter ${userInfo.USERNAME}`,
  },
  errors: {
    EMAIL: 'Invalid or missing email!',
    INCORRECT_MFA: `Incorrect one-time passcode`,
    INCORRECT_PASSWORD: `Incorrect ${userInfo.PASSWORD}`,
    INCORRECT_CURRENT_PASSWORD: `Incorrect ${userInfo.CURRENT_PASSWORD}`,
    INCORRECT_USER_NAME: `Incorrect ${userInfo.USERNAME} (email not in our system)`,
    INVALID_MFA: `Invalid one-time passcode`,
    NO_CURRENT_PASSWORD: `No ${userInfo.CURRENT_PASSWORD}`,
    NO_EMAIL: `No ${userInfo.EMAIL}`,
    NO_NEW_PASSWORD: `No ${userInfo.NEW_PASSWORD}`,
    NO_MFA_PASSCODE: `No one-time passcode`,
    NO_PASSWORD: `No ${userInfo.PASSWORD}`,
    NO_USER_NAME: `No ${userInfo.USERNAME}`,
    PASSWORD_UNSUFFICIENT: `New password doesn't meet requirements`,
    NETWORK_ERROR: 'Network error. Check your Internet connection.',
    API_OFFLINE: 'Service connection error',
  },
  success: {
    IMPORT_SUCCESS: 'Succesfully imported',
    NEW_PASSWORD_SET: `${userInfo.NEW_PASSWORD} was successfully set!`,
  },
};

export default messages;
