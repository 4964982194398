import React, { createContext, useReducer } from 'react';

import * as patientsApi from 'services/patients';
import { serializeApiErrors } from 'utils/errors';
import { loadAllPages, makeReducer, normalizePagingResponse } from 'utils/functions';
import { PagingInfo, Patient } from 'utils/types';

import { formatPatient } from './functions';
import {
  PatientsState,
  PatientsContext as PatientsContextType,
  CreatePatientCallback,
  GetPatientsCallback,
  GetPatientCallback,
  DeletePatientCallback,
  UpdatePatientCallback,
  LoadPatientsFunction,
} from './types';

const getInitialState = (): PatientsState => ({
  patients: [],
  patient: null,
  error: null,
  loading: false,
});

const PatientsContext = createContext<PatientsContextType>({
  ...getInitialState(),
  loadPatients: async () => {},
  getPatients: async () => {},
  resetPatients: () => {},
  getPatient: async () => {},
  createPatient: async () => {},
  updatePatient: async () => {},
  deletePatient: async () => {},
});

const PatientsProvider = (props: any) => {
  const [state, setState] = useReducer(makeReducer<PatientsState>(), getInitialState());

  const loadPatients: LoadPatientsFunction = async (params, callback?) => {
    setState({ loading: true, error: null });

    try {
      const query: Record<string, string> = {};

      if (params.projectId) {
        query.projectIds = params.projectId;
      }

      if (params.subjectIdLike) {
        query.subjectIdLike = params.subjectIdLike;
      }

      if (params.firstNameLike) {
        query.firstNameLike = params.firstNameLike;
      }

      if (params.lastNameLike) {
        query.lastNameLike = params.lastNameLike;
      }

      const sortBy = params.sortBy || 'createdAt';
      const isDescending = params.sortOrder !== 'asc';

      const results: any = [];
      let pagingInfo: PagingInfo;

      if (typeof params.ids === 'string') {
        const idList = params.ids === '' ? [] : params.ids.split(',');

        const promises: Promise<any>[] = [];
        for (let i = 0; i < idList.length; i += 50) {
          const idSubset = idList.slice(i, i + 50).join(',');
          promises.push(
            patientsApi.loadPatients(
              { ...query, ids: idSubset },
              {
                page: 1,
                pageLength: 50,
                // sorting is disabled because we're loading in chunks
              }
            )
          );

          if (promises.length === 5) {
            const data = await Promise.all(promises.splice(0, promises.length));
            results.push(...data.reduce((acc, val) => [...acc, ...val.results], []));
          }
        }

        if (promises.length > 0) {
          const data = await Promise.all(promises.splice(0, promises.length));
          results.push(...data.reduce((acc, val) => [...acc, ...val.results], []));
        }

        pagingInfo = {
          page: 1,
          pageLength: results.length,
          sortBy: 'createdAt',
          sortOrder: 'asc',
          totalCount: results.length,
        };
      } else {
        const data = await patientsApi.loadPatients(query, {
          page: params.page,
          pageLength: params.pageLength,
          sortBy,
          isDescending,
          includeTotalCount: params.includeTotalCount,
        });

        results.push(...data.results);

        pagingInfo = normalizePagingResponse(data.paging);
      }

      const formattedPatients = results.map(formatPatient);

      setState({
        patients: formattedPatients,
      });

      if (callback) {
        await callback(formattedPatients, pagingInfo);
      }
    } catch (e) {
      setState({ error: { timestamp: Date.now(), message: serializeApiErrors(e) } });

      if (callback) {
        await callback(null, null);
      }
    }

    setState({ loading: false });
  };

  const getPatients = async (projectId: string, callback?: GetPatientsCallback): Promise<void> => {
    setState({ loading: true, error: null });

    try {
      type PayloadType = Parameters<typeof patientsApi.getPatients>[0];
      const patients = await loadAllPages<Patient, PayloadType>(patientsApi.getPatients, projectId, {
        pageLength: 100,
      });

      const formattedPatients = patients.map(formatPatient);

      setState({
        patients: formattedPatients,
      });

      if (callback) {
        await callback(formattedPatients);
      }
    } catch (e) {
      setState({ error: { timestamp: Date.now(), message: serializeApiErrors(e) } });

      if (callback) {
        await callback(null);
      }
    }

    setState({ loading: false });
  };

  const getPatient = async (id: string, callback?: GetPatientCallback): Promise<void> => {
    setState({ loading: true, error: null });

    try {
      const patient: Patient = await patientsApi.getPatient(id);

      const formattedPatient = formatPatient(patient);

      setState({
        patient: formattedPatient,
      });

      if (callback) {
        await callback(formattedPatient);
      }
    } catch (e) {
      setState({
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
      });

      if (callback) {
        await callback(null);
      }
    }

    setState({ loading: false });
  };

  const createPatient = async (
    data: patientsApi.CreatePatientPayload,
    callback?: CreatePatientCallback
  ): Promise<void> => {
    setState({ loading: true, error: null });

    try {
      const patient: Patient = await patientsApi.createPatient(data);

      const formattedPatient = formatPatient(patient);

      setState({
        patients: [formattedPatient, ...state.patients],
      });

      if (callback) {
        await callback(formattedPatient);
      }
    } catch (e) {
      setState({ error: { timestamp: Date.now(), message: serializeApiErrors(e) } });

      if (callback) {
        await callback(null);
      }
    }

    setState({ loading: false });
  };

  const deletePatient = async (id: string, callback?: DeletePatientCallback): Promise<void> => {
    setState({ loading: true, error: null });

    try {
      const patient = await patientsApi.deletePatient(id);

      setState({
        patients: state.patients.filter((p) => p.id !== id),
        patient: state.patient && state.patient.id === id ? null : state.patient,
      });

      if (callback) {
        await callback(formatPatient(patient));
      }
    } catch (e) {
      setState({ error: { timestamp: Date.now(), message: serializeApiErrors(e) } });

      if (callback) {
        await callback(null);
      }
    }

    setState({ loading: false });
  };

  const updatePatient = async (
    id: string,
    data: patientsApi.UpdatePatientPayload,
    callback?: UpdatePatientCallback
  ): Promise<void> => {
    setState({ loading: true, error: null });

    try {
      const patient = await patientsApi.updatePatient(id, data);

      const formattedPatient = formatPatient(patient);

      setState({ patients: state.patients.map((p) => (p.id === id ? formattedPatient : p)) });

      if (callback) {
        await callback(formattedPatient);
      }
    } catch (e) {
      setState({ error: { timestamp: Date.now(), message: serializeApiErrors(e) } });

      if (callback) {
        await callback(null);
      }
    }

    setState({ loading: false });
  };

  const resetPatients = (): void => setState(getInitialState());

  return (
    <PatientsContext.Provider
      value={{
        patients: state.patients,
        patient: state.patient,
        loading: state.loading,
        error: state.error,
        loadPatients,
        getPatients,
        resetPatients,
        getPatient,
        createPatient,
        deletePatient,
        updatePatient,
      }}
      {...props}
    />
  );
};

const usePatients = (): PatientsContextType => React.useContext(PatientsContext);

export { PatientsProvider, usePatients };
