import React from 'react';

import { loadingBackdrop } from 'utils/dataTestConstants';
import './loading.scss';

// <Loading> component is a set of centrally positioned animating dots with an optional content blocking backdrop (most content is darkened)
export default function Loading() {
  return (
    <div className={'loader'}>
      {/* using "data-testid" instead of "data-test-id" to fit the following API: https://testing-library.com/docs/queries/bytestid/ */}
      <div className="lds-grid" data-testid={loadingBackdrop}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
