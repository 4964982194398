import React, { lazy } from 'react';

import { Route, Redirect } from 'react-router-dom';

import { useAuth } from 'providers/Auth';
import * as routes from 'utils/routes';

interface Props {
  path: string;
  page: () => Promise<any>;
  exact?: boolean;
}

export default function ProtectedRoute({ path, page, exact = false }: Props) {
  const { profile } = useAuth();
  const Page = lazy(page);

  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) =>
        profile ? (
          <Page />
        ) : (
          <Redirect
            to={{
              pathname: routes.ROUTES.LOGIN.PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
