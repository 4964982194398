import React, { createContext, useReducer } from 'react';

import * as deviceEventsApi from 'services/deviceEvents';
import { serializeApiErrors } from 'utils/errors';
import { loadAllPages, makeReducer } from 'utils/functions';
import { DeviceEvent } from 'utils/types';

import { DeviceEventsContext as DeviceEventsContextType, DeviceEventsState } from './types';

const getInitialState = (): DeviceEventsState => ({
  deviceEvents: [],
  loading: false,
  error: null,
});

const DeviceEventsContext = createContext<DeviceEventsContextType>({
  ...getInitialState(),
  getDeviceEvents: async () => {},
  getDeviceEventsForDevice: async () => {},
  removeDeviceEvents: () => {},
  resetDeviceEvents: () => {},
});

const DeviceEventsProvider = (props: any) => {
  const [state, setState] = useReducer(makeReducer<DeviceEventsState>(), getInitialState());

  const getDeviceEvents = async (projectId: string, callback?: any): Promise<void> => {
    try {
      setState({ loading: true, error: null });

      type PayloadType = Parameters<typeof deviceEventsApi.getDeviceEvents>[0];
      const deviceEvents = await loadAllPages<DeviceEvent, PayloadType>(deviceEventsApi.getDeviceEvents, projectId, {
        pageLength: 100,
        sortBy: 'createdAt',
        isDescending: true,
      });

      setState({
        deviceEvents: deviceEvents,
        loading: false,
      });

      if (callback) {
        await callback();
      }
    } catch (e) {
      setState({
        loading: false,
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
      });
    }
  };

  const getDeviceEventsForDevice = async (deviceIds: string[], callback?: any): Promise<void> => {
    try {
      setState({ loading: true, error: null });

      type PayloadType = Parameters<typeof deviceEventsApi.getDeviceEventsForDevice>[0];
      const deviceEvents = await loadAllPages<DeviceEvent, PayloadType>(
        deviceEventsApi.getDeviceEventsForDevice,
        deviceIds,
        {
          pageLength: 100,
          sortBy: 'createdAt',
          isDescending: true,
        }
      );

      setState({
        deviceEvents: [...state.deviceEvents, ...deviceEvents],
        loading: false,
      });

      if (callback) {
        await callback();
      }
    } catch (e) {
      setState({
        loading: false,
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
      });
    }
  };

  const removeDeviceEvents = (deviceIds: string[]) => {
    setState({ deviceEvents: state.deviceEvents.filter((ev) => !deviceIds.includes(ev.deviceId)) });
  };

  const resetDeviceEvents = (): void => {
    setState(getInitialState());
  };

  return (
    <DeviceEventsContext.Provider
      value={{
        deviceEvents: state.deviceEvents,
        loading: state.loading,
        error: state.error,
        getDeviceEvents,
        getDeviceEventsForDevice,
        removeDeviceEvents,
        resetDeviceEvents,
      }}
      {...props}
    />
  );
};

const useDeviceEvents = (): DeviceEventsContextType => React.useContext(DeviceEventsContext);

export { DeviceEventsProvider, useDeviceEvents };
