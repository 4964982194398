/* Nesting is our friend! */
/* https://github.com/facebook/react/issues/14620#issuecomment-455652349 */

import React, { FC } from 'react';

import { FlagProvider, IConfig } from '@unleash/proxy-client-react';

import { AuthProvider } from 'providers/Auth';
import { DeviceEventsProvider } from 'providers/DeviceEvents';
import { DevicesProvider } from 'providers/Devices';
import { DownloadsProvider } from 'providers/Downloads';
import { IdleLogoutProvider } from 'providers/IdleLogout';
import { OrdersProvider } from 'providers/Orders';
import { PatientImportsProvider } from 'providers/PatientImports';
import { PatientsProvider } from 'providers/Patients';
import { ProjectLaboratoriesProvider } from 'providers/ProjectLaboratories';
import { ProjectMetricsProvider } from 'providers/ProjectMetrics';
import { ProjectsProvider } from 'providers/Projects';
import { TestResultsProvider } from 'providers/TestResults';

const providers = [
  IdleLogoutProvider,
  AuthProvider,
  ProjectsProvider,
  ProjectLaboratoriesProvider,
  DevicesProvider,
  DeviceEventsProvider,
  PatientsProvider,
  DownloadsProvider,
  OrdersProvider,
  PatientImportsProvider,
  TestResultsProvider,
  ProjectMetricsProvider,
];

interface AppProvidersProps {
  unleashConfig: IConfig;
}

const AppProviders: FC<AppProvidersProps> = ({ children, unleashConfig: config }) =>
  providers.reduce(
    (acc, Provider) => <Provider>{acc}</Provider>,
    <FlagProvider config={config}>{children}</FlagProvider>
  );

export default AppProviders;
