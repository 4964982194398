import React, { FC, createContext, useReducer } from 'react';

import * as downloadsApi from 'services/downloads';
import { serializeApiErrors } from 'utils/errors';
import { makeReducer } from 'utils/functions';

import { wait } from './functions';
import { Context, Options, State } from './types';

const getInitialState = (): State => ({
  loading: false,
  error: null,
});

const DownloadsContext = createContext<Context>({
  ...getInitialState(),
  downloadPatients: async () => {},
  downloadLabReports: async () => {},
});

const DownloadsProvider: FC = (props) => {
  const [state, setState] = useReducer(makeReducer<State>(), getInitialState());

  const openUrl = (url: string): void => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.rel = 'noopener';
    link.click();
  };

  const downloadJob = async (apiCall: () => Promise<any>, options?: Options): Promise<void> => {
    setState({
      loading: true,
      error: null,
    });

    const maxWaitTimeMs = options && typeof options.maxWaitTimeMs === 'number' ? options.maxWaitTimeMs : 10_000;
    const delayBetweenRequestsMs =
      options && typeof options.delayBetweenRequestsMs === 'number' ? options.delayBetweenRequestsMs : 1_000;

    try {
      const result = await apiCall();
      const exportId = result.id;

      await wait(1000);

      let statusCheck = await downloadsApi.getExportStatus(exportId);
      let status = statusCheck.status;

      let maxAttempts = Math.floor(maxWaitTimeMs / delayBetweenRequestsMs);

      while (status !== 'complete' && maxAttempts > 0) {
        if (status === 'failed') {
          setState({
            error: { timestamp: Date.now(), message: statusCheck.info.errorMessage },
          });
          return;
        }

        await wait(delayBetweenRequestsMs);
        statusCheck = await downloadsApi.getExportStatus(exportId);
        status = statusCheck.status;
        maxAttempts -= 1;
      }

      if (status === 'complete') {
        const fileResult = await downloadsApi.getFile(statusCheck.exportedFileId);
        openUrl(fileResult.downloadUrl);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setState({
        loading: false,
      });
    }
  };

  const download = async (apiCall: () => Promise<any>, options?: Options): Promise<void> => {
    setState({
      loading: true,
      error: null,
    });

    const maxWaitTimeMs = options && typeof options.maxWaitTimeMs === 'number' ? options.maxWaitTimeMs : 10_000;
    const delayBetweenRequestsMs =
      options && typeof options.delayBetweenRequestsMs === 'number' ? options.delayBetweenRequestsMs : 1_000;

    try {
      const response = await apiCall();

      const getUrl = response.getUrl;
      const headUrl = response.headUrl;

      await wait(1000);

      let isReady = await downloadsApi.isDownloadReady(headUrl);
      let maxAttempts = Math.floor(maxWaitTimeMs / delayBetweenRequestsMs);

      while (!isReady && maxAttempts > 0) {
        await wait(delayBetweenRequestsMs);
        isReady = await downloadsApi.isDownloadReady(headUrl);
        maxAttempts -= 1;
      }

      if (!isReady) {
        setState({
          error: { timestamp: Date.now(), message: 'Download failed' },
        });
        return;
      } else {
        openUrl(getUrl);
      }
    } catch (e) {
      console.log(e);
      setState({
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
      });
    } finally {
      setState({
        loading: false,
      });
    }
  };

  const downloadPatients = async (projectId: string): Promise<void> =>
    downloadJob(() => downloadsApi.downloadPatients(projectId), {
      maxWaitTimeMs: 900_000,
      delayBetweenRequestsMs: 2_000,
    });

  const downloadLabReports = async (testResultIds: string[]): Promise<void> =>
    download(() => downloadsApi.downloadLabReports(testResultIds), {
      maxWaitTimeMs: 60_000,
      delayBetweenRequestsMs: 1_000,
    });

  return (
    <DownloadsContext.Provider
      value={{
        loading: state.loading,
        error: state.error,
        downloadPatients,
        downloadLabReports,
      }}
      {...props}
    />
  );
};

const useDownloads = (): Context => React.useContext(DownloadsContext);

export { DownloadsProvider, useDownloads };
