import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { lazy, Suspense } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import './index.scss';

import Loading from 'components/Loading';
import ProtectedRoute from 'components/ProtectedRoute';
import AppProviders from 'providers/AppProviders';
import { useAuth } from 'providers/Auth';
import { CI_ENVIRONMENT_NAME, FEATURE_FLAG_PROXY_CLIENT_KEY, FEATURE_FLAG_PROXY_URL } from 'utils/config';
import * as routes from 'utils/routes';

import Layout from './layout';

// Initialize the React Query client singleton
const reactQueryClient = new QueryClient();

const searchParams = new URLSearchParams(window.location.search);
if (searchParams && searchParams.has('api')) {
  const url = searchParams.get('api');
  if (url) {
    const allowedValues = [process.env.REACT_APP_API_URL, process.env.REACT_APP_ALTERNATE_API_URL];
    if (allowedValues.includes(url)) {
      localStorage.setItem('CORE_API_URL', url);
    }
  } else {
    localStorage.removeItem('CORE_API_URL');
  }
}

const lazyLoad = (page: any) => {
  return lazy(page);
};

const unleashConfig = {
  url: FEATURE_FLAG_PROXY_URL,
  clientKey: FEATURE_FLAG_PROXY_CLIENT_KEY,
  appName: CI_ENVIRONMENT_NAME, // Assigning the environment name to the appName is intentional. See bug here: https://gitlab.com/gitlab-org/gitlab/-/issues/354087
  refreshInterval: 15,
};

const App = () => {
  const { profile, loading: isAuthLoading } = useAuth();

  const isCustomerSponsor = profile?.role === 'customerSponsor';

  // If authentication flow is running (login or logout), render a simple spinner only.
  // It's too early to tell if any additional data needs to be fetched, so wait for authentication to complete first.
  if (isAuthLoading) {
    return <Loading />;
  }

  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Redirect exact from="/" to={routes.ROUTES.PROJECTS.PATH} />
          <Route path={routes.ROUTES.LOGIN.PATH} exact component={lazyLoad(routes.ROUTES.LOGIN.PAGE)} />
          <Route path={routes.ROUTES.LOGOUT.PATH} exact component={lazyLoad(routes.ROUTES.LOGOUT.PAGE)} />
          <Route
            path={routes.ROUTES.CONFIRM_PASSWORD.PATH}
            exact
            component={lazyLoad(routes.ROUTES.CONFIRM_PASSWORD.PAGE)}
          />
          <Route
            path={routes.ROUTES.RESET_PASSWORD.PATH}
            exact
            component={lazyLoad(routes.ROUTES.RESET_PASSWORD.PAGE)}
          />
          <ProtectedRoute page={routes.ROUTES.PROJECT.PAGE} exact path={routes.ROUTES.PROJECT.PATH} />
          <ProtectedRoute page={routes.ROUTES.PROJECTS.PAGE} exact path={routes.ROUTES.PROJECTS.PATH} />

          {!isCustomerSponsor && (
            <ProtectedRoute page={routes.ROUTES.PATIENT_IMPORT.PAGE} exact path={routes.ROUTES.PATIENT_IMPORT.PATH} />
          )}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Layout>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={reactQueryClient}>
      <Router>
        <SnackbarProvider maxSnack={3}>
          <AppProviders unleashConfig={unleashConfig}>
            {/* ℹ️ RQ Devtools will only be rendered in development. More info, including config options: https://tanstack.com/query/latest/docs/react/devtools */}
            <ReactQueryDevtools initialIsOpen={false} />
            <App />
          </AppProviders>
        </SnackbarProvider>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

function NotFound() {
  return (
    <div className="not-found-wrapper">
      <span className="not-found" />
      ...Page not found!
    </div>
  );
}
