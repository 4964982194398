import React, { createContext, useReducer } from 'react';

import * as api from 'services/projectLaboratories';
import { serializeApiErrors } from 'utils/errors';
import { makeReducer } from 'utils/functions';

import { State, Context } from './types';

const getInitialState = (): State => ({
  projectLaboratories: [],
  error: null,
  loading: false,
});

const ProjectLaboratoriesContext = createContext<Context>({
  ...getInitialState(),
  getProjectLaboratories: async () => {},
  resetProjectLaboratories: () => {},
});

const ProjectLaboratoriesProvider = (props: any) => {
  const [state, setState] = useReducer(makeReducer<State>(), getInitialState());

  const getProjectLaboratories = async (projectId: string, callback: any): Promise<void> => {
    try {
      setState({ loading: true, error: null });

      const results = await api.getProjectLaboratories(projectId);

      setState({
        loading: false,
        projectLaboratories: results,
      });

      if (callback) {
        await callback();
      }
    } catch (e) {
      setState({ loading: false, error: { timestamp: Date.now(), message: serializeApiErrors(e) } });
    }
  };

  const resetProjectLaboratories = () => {
    setState(getInitialState());
  };

  return (
    <ProjectLaboratoriesContext.Provider
      value={{
        projectLaboratories: state.projectLaboratories,
        loading: state.loading,
        error: state.error,
        getProjectLaboratories,
        resetProjectLaboratories,
      }}
      {...props}
    />
  );
};

const useProjectLaboratories = (): Context => React.useContext(ProjectLaboratoriesContext);

export { ProjectLaboratoriesProvider, useProjectLaboratories };
