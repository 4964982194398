import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

import * as config from 'utils/config';

import Auth from '../utils/cognito';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  ignoreAuthorization: boolean;
}

const httpClient = axios.create({
  baseURL: config.API_URL,
});

httpClient.interceptors.request.use(async (axiosConfig): Promise<any> => {
  const config = { ...axiosConfig } as CustomAxiosRequestConfig;

  const baseURL = localStorage.getItem('CORE_API_URL');
  if (baseURL) {
    config.baseURL = baseURL;
  }

  if (config.ignoreAuthorization) {
    return config;
  }

  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (e) {
    console.log('Not authenticated', e);
  }

  return config;
});

const responseSuccessLogger = (response: any) => {
  return response;
};

const responseErrorLogger = (error: any) => {
  let methodStr;
  let urlStr;

  if (error.response) {
    methodStr = error.response.config.method ? error.response.config.method.toUpperCase() : '';
    urlStr = (error.response.config.baseURL || '') + (error.response.config.url || '');
    console.error(`Response error: ${methodStr} ${urlStr}:`, error.response);
  } else {
    methodStr = error.config.method ? error.config.method.toUpperCase() : '';
    urlStr = (error.config.baseURL || '') + (error.config.url || '');
    console.error(`Response error: ${methodStr} ${urlStr}:`, error);
  }

  return Promise.reject(error);
};

httpClient.interceptors.response.use(responseSuccessLogger, responseErrorLogger);

// eslint-disable-next-line import/no-named-as-default-member
export const makeCancelTokenSource = (): CancelTokenSource => axios.CancelToken.source();

export type { CancelTokenSource } from 'axios';

export default httpClient;
