import React, { useRef, useState } from 'react';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';

import logo from 'assets/Tasso_Lockup_ha.svg';
import { useAuth } from 'providers/Auth';
import { useIdleLogout } from 'providers/IdleLogout';
import { currentDesignTheme } from 'theme/currentDesignTheme';
import { ROUTES as routes } from 'utils/routes';

import '../styles/header.scss';

export default function Headers() {
  const { profile } = useAuth();
  const { logoutHandler: logout } = useIdleLogout();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    const { current }: any = anchorRef;
    !current?.contains(event.target) && setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  function handleLogout(event: any) {
    logout();
    handleClose(event);
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={currentDesignTheme}>
        <div className={'header'}>
          <img className={'logo'} src={logo} alt={logo} onClick={() => history.push(routes.PROJECTS.PATH)} />
          <div className={'menu'}>
            {!!profile && (
              <>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  className={'menu_title'}
                >
                  <div className="menu_username">{profile.username}</div>
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  className={'menu_paper'}
                >
                  {({ TransitionProps }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: 'center top',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
          </div>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
