import { httpClient } from 'services/index';

export const downloadPatients = (projectId: string): Promise<any> =>
  httpClient.post('/patientExportJobs', { projectId }).then((_) => _.data.results);

export const getExportStatus = (exportId: string): Promise<any> =>
  httpClient.get(`/patientExportJobs/${exportId}`).then((_) => _.data.results);

export const getFile = (fileId: string): Promise<any> =>
  httpClient.get(`/userFiles/${fileId}`).then((_) => _.data.results);

export const downloadLabReports = (testResultIds: string[]): Promise<any> =>
  httpClient.post(`/downloads/labReport`, { testResultIds }).then((_) => _.data.results);

export const isDownloadReady = (url: string): Promise<boolean> =>
  httpClient
    .head(url, { ignoreAuthorization: true } as any)
    .then((_) => _.status === 200)
    .catch(() => false);
