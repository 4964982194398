/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useReducer } from 'react';

import { DateTime } from 'luxon';

import * as api from 'services/orders';
import { serializeApiErrors } from 'utils/errors';
import { loadAllPages, loadByIdsInBatches, makeReducer } from 'utils/functions';
import { Device, Order } from 'utils/types';

import * as types from './types';

const getInitialState = (): types.State => ({
  orders: [],
  order: null,
  loading: false,
  error: null,
});

const Context = createContext<types.Context>({
  ...getInitialState(),
  loadPendingOrdersForPatients: async () => {},
  getOrders: async () => {},
  createOrder: async () => {},
  resetOrders: () => {},
  cancelOrder: async () => {},
});

const OrdersProvider: React.FC = (props) => {
  const [state, setState] = useReducer(makeReducer<types.State>(), getInitialState());

  const loadPendingOrdersForPatients: types.LoadOrdersForPatientsFunction = async (
    params,
    devices: Device[],
    callback?
  ) => {
    setState({ loading: true, error: null });

    try {
      const { patientIds, projectId } = params;

      const query: Record<string, string | boolean> = { projectIds: projectId };

      if (params.notStatuses) {
        query.notStatuses = `${params.notStatuses.join(',')},cancelled`;
      } else {
        query.notStatuses = 'cancelled';
      }

      if (Array.isArray(params.statuses)) {
        query.statuses = params.statuses.join(',');
      }

      query.internalShipByMin = DateTime.now().toFormat('yyyy-MM-dd');

      const pendingOrders = await loadByIdsInBatches<Order>(api.getOrders, 'patientIds', patientIds, query);
      const orderIds = new Set(devices.map((device) => device.orderId));
      const results = pendingOrders.filter((order) => !orderIds.has(order.id));

      setState({
        orders: results,
      });

      if (callback) {
        await callback(results);
      }
    } catch (e) {
      setState({
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState({ loading: false });
    }
  };

  const getOrders: types.GetOrdersFunction = async (params, _options = {}, callback) => {
    setState({ loading: true, error: null });

    try {
      const query: api.GetOrdersPayload = {};

      let orders: Order[];

      if (params.notStatuses) {
        query.notStatuses = `${params.notStatuses.join(',')},cancelled`;
      } else {
        query.notStatuses = 'cancelled';
      }

      if (params.patientIds) {
        orders = await loadByIdsInBatches<Order>(api.getOrders, 'patientIds', params.patientIds);
        query.patientIds = params.patientIds.join(',');
      } else {
        type PayloadType = Parameters<typeof api.getOrders>[0];
        orders = await loadAllPages<Order, PayloadType>(api.getOrders, query, {
          pageLength: 100,
        });
      }

      setState({
        orders,
      });

      if (callback) {
        await callback(orders);
      }
    } catch (e) {
      setState({
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState({ loading: false });
    }
  };

  const createOrder: types.CreateOrderFunction = async (data, _options = {}, callback) => {
    setState({ loading: true, error: null });

    try {
      const order = await api.createOrder(data);

      setState({
        orders: [...state.orders, order],
      });

      if (callback) {
        await callback(order);
      }
    } catch (e) {
      setState({
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState({ loading: false });
    }
  };

  const cancelOrder: types.CancelOrderFunction = async (orderId, _options = {}, callback) => {
    setState({ loading: true, error: null });

    try {
      const cancelOrderResponse = await api.updateOrder(orderId, { status: 'cancelled' });

      setState({
        orders: state.orders.filter((order) => order.id !== orderId),
        order: state.order && state.order.id === orderId ? null : state.order,
      });

      if (callback) {
        await callback(cancelOrderResponse.results);
      }
    } catch (e) {
      setState({
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState({ loading: false });
    }
  };

  const resetOrders: types.ResetOrdersFunction = () => {
    setState({ ...getInitialState() });
  };

  return (
    <Context.Provider
      value={{
        orders: state.orders,
        order: state.order,
        loading: state.loading,
        error: state.error,
        loadPendingOrdersForPatients,
        getOrders,
        createOrder,
        resetOrders,
        cancelOrder,
      }}
      {...props}
    />
  );
};

const useOrders = (): types.Context => React.useContext(Context);

export { OrdersProvider, useOrders };
