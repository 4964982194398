import React from 'react';
import '../styles/footer.scss';

export default function Footer() {
  return (
    <div className={'footer'}>
      Tasso ©2020 &bull; All rights reserved &bull;&nbsp;
      <a href="https://www.tassoinc.com/security-vulnerability-disclosure" target="_blank" rel="noopener noreferrer">
        Security Vulnerability Disclosure
      </a>
    </div>
  );
}
