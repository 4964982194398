import React, { createContext, useReducer } from 'react';

import * as api from 'services/patientImports';
import { serializeApiErrors } from 'utils/errors';
import { makeReducer } from 'utils/functions';

import * as types from './types';

const getInitialState = (): types.State => ({
  importId: '',
  import: null,
  error: null,
  loading: false,
  dataError: null,
  apiCallCount: 0,
});

const PatientImportsContext = createContext<types.Context>({
  ...getInitialState(),
  createPatientImport: async () => {},
  getPatientImport: async () => {},
  resetPatientImports: () => {},
});

const PatientImportsProvider = (props: any) => {
  const [state, setState] = useReducer(makeReducer<types.State>(), getInitialState());

  const createPatientImport: types.CreatePatientImportFunction = async (data, options, callback) => {
    setState({ loading: true, error: null, dataError: null });

    try {
      const result: types.PatientImport = await api.createPatientImport(data);

      setState({
        importId: result.id,
        import: result,
        apiCallCount: state.apiCallCount + 1,
      });

      if (callback) {
        await callback(result);
      }
    } catch (e: any) {
      setState({
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState({ loading: false });
    }
  };

  const getPatientImport: types.GetPatientImportFunction = async (request, options, callback) => {
    setState({ loading: true, error: null, dataError: null });

    try {
      const result: types.PatientImport = await api.getPatientImport(request.importId, request.hydration ?? []);

      if (result.errorDetails) {
        try {
          const json = JSON.parse(result.errorDetails);

          const keys = Object.keys(json);

          // Check if the `errorMessage` is in fact an encoded validation error
          if (keys.includes('name') && keys.includes('property') && keys.includes('message')) {
            if (json.name === 'fileValidationError') {
              setState({
                dataError: JSON.parse(json.message),
              });
            } else {
              setState({
                error: { timestamp: Date.now(), message: json.message },
              });
            }
          }
        } catch (ex: any) {
          setState({
            error: { timestamp: Date.now(), message: result.errorDetails },
          });
        }
      }

      if (options && options.fieldsToRefresh) {
        // Override only specific fields, leave the rest of the in-memory object (patient import) untouched.
        const overrides = options.fieldsToRefresh.reduce((acc, val) => ({ ...acc, [val]: result[val] }), {});

        setState({
          import: state.import ? { ...state.import, ...overrides } : state.import,
          apiCallCount: state.apiCallCount + 1,
        });
      } else {
        setState({
          import: result,
          apiCallCount: state.apiCallCount + 1,
        });
      }

      if (callback) {
        await callback(result);
      }
    } catch (e: any) {
      setState({
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
        apiCallCount: state.apiCallCount + 1,
      });
    } finally {
      setState({ loading: false });
    }
  };

  const resetPatientImports: types.ResetPatientImportsFunction = () => {
    setState(getInitialState());
  };

  return (
    <PatientImportsContext.Provider
      value={{
        importId: state.importId,
        import: state.import,
        loading: state.loading,
        error: state.error,
        dataError: state.dataError,
        apiCallCount: state.apiCallCount,
        createPatientImport,
        getPatientImport,
        resetPatientImports,
      }}
      {...props}
    />
  );
};

const usePatientImports = (): types.Context => React.useContext(PatientImportsContext);

export { PatientImportsProvider, usePatientImports };
