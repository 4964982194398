import React, { FC } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

interface IdleLogoutPromptModalProps {
  visible: boolean;
  onStayLoggedIn: () => void;
  onLogout: () => Promise<void>;
}

const IdleLogoutWarningModal: FC<IdleLogoutPromptModalProps> = ({ visible, onStayLoggedIn, onLogout }) => (
  <Dialog open={visible} disableEscapeKeyDown>
    <DialogContent>For your security, you are about to be logged out due to inactivity.</DialogContent>
    <DialogActions>
      <Button onClick={onStayLoggedIn} color="primary">
        Stay Logged In
      </Button>
      <Button
        color="primary"
        variant="contained"
        disabled={!open}
        onClick={async () => {
          await onLogout();
        }}
      >
        Log Me Out
      </Button>
    </DialogActions>
  </Dialog>
);

export default IdleLogoutWarningModal;
