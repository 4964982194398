import { Reducer } from 'react';

import { DateTime, Settings } from 'luxon';

import { AuthUserProfile } from 'providers/Auth/types';
import {
  ALERT_TYPES,
  TASSO_STATUS_DIRECT_TO_PATIENT,
  TASSO_STATUS_AT_CUSTOMER_SITE,
  TASSO_ERROR_STATUS,
  PendingOrderStatuses,
} from 'utils/constants';

import { ApiCall, PagingInfo, PagingParams, PagingResponse, ProjectDistributionModel, TassoStatus } from './types';

// ----------------------- Reducer --------------------------------------

export const makeReducer =
  <T>(): Reducer<T, Partial<T>> =>
  (current, next) => ({ ...current, ...next });

// ----------------------- Date Format ----------------------------------
/**
 * If you pass in any ISO string, you will be returned it in the consistent ISO Date format.
 * If you don't pass in an ISO string that Luxon can parse, you will be returned undefined.
 *
 * @param date any ISO8601 compliant date string
 * @returns an ISO8601 date string like "2021-08-24" or undefined
 */
export function dateFormat(date: string) {
  // configures Luxon to not just give up and return null if it's given bogus input
  // Reference: https://moment.github.io/luxon/#/validity?id=throwoninvalid
  Settings.throwOnInvalid = true;

  try {
    const result = DateTime.fromISO(date).toISODate();
    return result;
  } catch (err) {
    // this will happen if the string wasn't an ISO date
    // the error will look something like "Error: Invalid DateTime: unsupported zone: the zone "America/Blorp" is not supported"
    // more details on the information carried in the Luxon error message: https://moment.github.io/luxon/#/validity?id=throwoninvalid
    return undefined;
  }
}

// ----------------------- HANDLE KEY PRESS ------------------------------

export function handleKeyPress(e: any, execute: any) {
  e.preventDefault();
  if (e.key === 'Enter') {
    execute();
  }
}

// ----------------------- DEBOUNCE --------------------------------------

export const debounce = (function () {
  let timeoutId: any;

  return {
    handleChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, func: any, time = 250) => {
      const {
        target: { value },
      } = e;
      e.preventDefault();

      !!timeoutId && clearTimeout(timeoutId);

      if (value) {
        timeoutId = setTimeout(() => {
          func(value);
          timeoutId = undefined;
        }, time);
      } else {
        func(value);
      }
    },
  };
})();

// ----------------------- Notification --------------------------------------

export function notification(
  message: string,
  callback: (message: string, config: any) => void,
  type = ALERT_TYPES.ERROR,
  duration: number | null = 5000,
  key?: string
) {
  callback(message, {
    key,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    preventDuplicate: true,
    variant: type,
    autoHideDuration: duration,
  });
}

// ----------------------- Copy to Clipboard -----------------------------------

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

// -----------------------------------------------------------------------------
// Type checks
// -----------------------------------------------------------------------------
export const isString = (value: any) => typeof value === 'string' || value instanceof String;

export const isNumber = (value: any) => typeof value === 'number' && isFinite(value);

export const isArray = (value: any) => Array.isArray(value);

export const isFunction = (value: any) => typeof value === 'function';

export const isObject = (value: any) => value && typeof value === 'object' && value.constructor === Object;

export const isNull = (value: any) => value === null;

export const isUndefined = (value: any) => typeof value === 'undefined';

export const isBoolean = (value: any) => typeof value === 'boolean';

export const isRegExp = (value: any) => value && typeof value === 'object' && value.constructor === RegExp;

export const isDate = (value: any) => value instanceof Date;

export const isSymbol = (value: any) => typeof value === 'symbol';

// -----------------------------------------------------------------------------

export const capitalize = (s: string) => s.toLowerCase().replace(/\b./g, (a) => a.toUpperCase());

/**
 * Converts a camelCasedString to Sentence Case.
 * Example: input "firstClass" -> output "First Class"
 * Tip: don't try to mix in numbers, it'll look weird. Example: input "fedEx2Day" -> output "Fed Ex2 Day"
 */
export function camelCaseToSentenceCase(s: string) {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function cloneDeep(e: any) {
  return JSON.parse(JSON.stringify(e));
}

export const removeUndefinedFromObject = <T extends Record<string, unknown>>(obj: T): Partial<T> => {
  const keys = Object.keys(obj) as (keyof T)[];

  return keys.reduce<Partial<T>>((acc, key) => {
    if (typeof obj[key] !== 'undefined') {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};

export const removeEmptyStringFromObject = <T extends Record<string, unknown>>(obj: T): Partial<T> => {
  const keys = Object.keys(obj) as (keyof T)[];

  return keys.reduce<Partial<T>>((acc, key) => {
    if (typeof obj[key] === 'string') {
      const asserted = obj[key] as string;
      if (asserted.length > 0) {
        acc[key] = obj[key];
      }
    } else {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};

export const loadAllPages = async <R = any, P = any>(
  apiCall: ApiCall<P, R>,
  payload: P,
  paging: PagingParams = {}
): Promise<R[]> => {
  const results: R[] = [];

  const pageLength = paging.pageLength || undefined;
  const sortBy = paging.sortBy || undefined;
  const isDescending = paging.isDescending || undefined;
  let page = 1;
  let totalPages = -1;

  do {
    const data = await apiCall(payload, { page, pageLength, sortBy, isDescending, includeTotalCount: page === 1 });

    if (data && data.paging && data.paging.totalCount) {
      const totalCount = parseInt(data.paging.totalCount, 10); // currently totalCount comes back as string (bug?)
      totalPages = Math.ceil(totalCount / data.paging.pageLength);
    }
    results.push(...data.results);
    page += 1;
  } while (page <= totalPages);

  return results;
};

/**
 * Given a long list of ids, break it up into multiple groups (batches).
 * Each group means a separate HTTP request. At the end join all results
 * together into a single list of results.
 *
 * Send requests with 30 ids at a time. Core API allows at most 2,048 characters
 * in query strings, so it's important to stay below that limit to avoid errors.
 * UUID v4 length is 36 characters, so 36 * 30 = 1,080 characters.
 * With 29 comas that separate these ids the number becomes 1,109 characters.
 * The remanining 2,048 - 1,109 = 939 characters are available for other uses.
 */
export const loadByIdsInBatches = async <R>(
  apiCall: ApiCall<any, R>,
  idParameterName: string,
  idParameterValues: string[],
  otherQueryStringParameters: Record<string, string | boolean | number | string[]> = {}
): Promise<R[]> => {
  const idGroupSize = 30;

  const promises: Promise<R[]>[] = [];

  for (let i = 0; i < idParameterValues.length; i += idGroupSize) {
    const idList = idParameterValues.slice(i, i + idGroupSize);

    promises.push(
      loadAllPages<R, any>(
        apiCall,
        { ...otherQueryStringParameters, [idParameterName]: idList.join(',') },
        {
          pageLength: 1000,
          sortBy: 'createdAt',
          isDescending: true,
        }
      )
    );
  }

  const data = await Promise.all(promises);

  const results = data.reduce<R[]>((acc, val) => [...acc, ...val], []);

  return results;
};

export const getTassoStatus = (status: string, distributionModel: ProjectDistributionModel): TassoStatus | null => {
  const statusConfig: Record<string, string> = {
    ...(distributionModel === 'atCustomerSite' ? TASSO_STATUS_AT_CUSTOMER_SITE : TASSO_STATUS_DIRECT_TO_PATIENT),
    ...TASSO_ERROR_STATUS,
  };

  if (status === 'inStock' || status === 'createdForOrder' || status === 'readyToShip') {
    return 'atTasso';
  } else if (distributionModel === 'directToPatient' && status === 'awaitingPickup') {
    return 'inTransitToPatient';
  } else if (Object.keys(statusConfig).includes(status)) {
    // Any valid status belonging to the specific distribution model should be returned.
    return status as TassoStatus;
  } else if (status === 'replaced') {
    return 'replaced';
  } else {
    // Invalid statuses
    return null;
  }
};

export const tassoStatusToDeviceStatus = (
  status: TassoStatus,
  distributionModel: ProjectDistributionModel
): string[] => {
  const statusConfig: Record<string, string> = {
    ...(distributionModel === 'atCustomerSite' ? TASSO_STATUS_AT_CUSTOMER_SITE : TASSO_STATUS_DIRECT_TO_PATIENT),
    ...TASSO_ERROR_STATUS,
  };

  if (status === 'atTasso') {
    return ['inStock', 'createdForOrder', 'readyToShip'];
  } else if (distributionModel === 'directToPatient' && status === 'inTransitToPatient') {
    return ['awaitingPickup', 'inTransitToPatient'];
  } else if (Object.keys(statusConfig).includes(status)) {
    // Any valid status belonging to the specific distribution model should be returned.
    return [status];
  } else {
    // invalid statuses
    return [];
  }
};

// sort by createdAt in ascending order (oldest to newest date)
export const sortByCreatedAtAsc = (a: any, b: any): -1 | 0 | 1 => {
  const ts1 = new Date(a.createdAt).getTime();
  const ts2 = new Date(b.createdAt).getTime();
  if (ts1 === ts2) {
    return 0;
  }

  return ts1 > ts2 ? 1 : -1;
};

// sort by createdAt in descending order (newest to oldest date)
export const sortByCreatedAtDesc = (a: any, b: any): -1 | 0 | 1 => {
  const ts1 = new Date(a.createdAt).getTime();
  const ts2 = new Date(b.createdAt).getTime();
  if (ts1 === ts2) {
    return 0;
  }

  return ts1 > ts2 ? -1 : 1;
};

// sort by occurredAt then, if neither have it use createdAt in Asc order (oldest to newest)
export const sortByOccurredAtThenCreatedAtAsc = (a: any, b: any): number => {
  const occurredAtA = a.occurredAt ? new Date(a.occurredAt).getTime() : 0;
  const occurredAtB = b.occurredAt ? new Date(b.occurredAt).getTime() : 0;
  //if either has the occurredAt, that one wins no matter
  if (occurredAtA > 0 || occurredAtB > 0) {
    if (occurredAtB === 0) {
      return -1;
    }
    if (occurredAtA === 0) {
      return 1;
    }
    //if both have it, just substract
    return occurredAtA - occurredAtB;
  }
  //if we get this far, neither have occurredAt
  const createdA = new Date(a.createdAt).getTime();
  const createdB = new Date(b.createdAt).getTime();

  return createdA - createdB;
};

export const getDeviceStatuses = (withResults: boolean, distributionModel: ProjectDistributionModel) => {
  const statusConfig: Record<string, string> = {
    ...(distributionModel === 'atCustomerSite' ? TASSO_STATUS_AT_CUSTOMER_SITE : TASSO_STATUS_DIRECT_TO_PATIENT),
    ...TASSO_ERROR_STATUS,
  };

  if (!withResults) {
    delete statusConfig.resultsReady;
  }

  return statusConfig;
};

export const normalizePagingResponse = (paging: PagingResponse): PagingInfo => {
  const result: PagingInfo = {
    page: paging.page,
    pageLength: paging.pageLength,
    sortBy: paging.sortBy,
    sortOrder: paging.isDescending ? 'desc' : 'asc',
    totalCount: typeof paging.totalCount === 'string' ? parseInt(paging.totalCount, 10) : paging.totalCount || -1,
  };

  return result;
};

export const getTassoStatusForDisplay = (deviceStatus: string, distributionModel: ProjectDistributionModel): string => {
  const tassoStatus = getTassoStatus(deviceStatus, distributionModel);

  if (!tassoStatus) {
    return '';
  }

  let status: string;

  if (distributionModel === 'atCustomerSite') {
    status =
      tassoStatus in TASSO_STATUS_AT_CUSTOMER_SITE
        ? TASSO_STATUS_AT_CUSTOMER_SITE[tassoStatus as keyof typeof TASSO_STATUS_AT_CUSTOMER_SITE]
        : '';
  } else {
    status =
      tassoStatus in TASSO_STATUS_DIRECT_TO_PATIENT
        ? TASSO_STATUS_DIRECT_TO_PATIENT[tassoStatus as keyof typeof TASSO_STATUS_DIRECT_TO_PATIENT]
        : '';
  }

  return status;
};

/**
 * An order is considered "pending" until it reaches at least
 * the "logisticsComplete" status.
 */
export const isPendingOrder = <T extends { status: string }>(order: T): boolean =>
  PendingOrderStatuses.has(order.status);

interface GetOrderStatusForDisplayParams {
  isPendingFulfillment: boolean;
  deviceStatus: string;
  projectDistributionModel: ProjectDistributionModel;
}
/**
 * Returns a user frieldly version of order status.
 */
export const getOrderStatusForDisplay = ({
  isPendingFulfillment,
  deviceStatus,
  projectDistributionModel,
}: GetOrderStatusForDisplayParams): string => {
  {
    return isPendingFulfillment
      ? 'Pending Fulfillment'
      : getTassoStatusForDisplay(deviceStatus, projectDistributionModel);
  }
};

export const isUsingNewUi = (profile: AuthUserProfile | null) => {
  return !!profile?.useNewUi;
};
