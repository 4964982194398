import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export type GetOrdersPayload = {
  patientIds?: string;
  notStatuses?: string;
};

export const getOrders = (params: Record<string, any>, paging: PagingRequest): Promise<any> =>
  httpClient.get('/orders', { params: { ...params, ...paging } }).then((_) => _.data);

export type CreateOrderPayload = {
  patientId?: string;
  customerProvidedNpi?: string;
  customerProvidedProviderFirstName?: string;
  customerProvidedProviderLastName?: string;
  deviceBarcode?: string;
  replacementReason?: string;
  replacementHarmCaused?: boolean;
  replacementDescription?: string;
  replacesDeviceId?: string;
  shipBy?: string;
};

export const createOrder = (data: CreateOrderPayload): Promise<any> =>
  httpClient.post('/orders', data).then((_) => _.data.results);

type UpdateOrderPayload = {
  status?: string;
};

export const updateOrder = (orderId: string, body: UpdateOrderPayload): Promise<any> =>
  httpClient.patch(`/orders/${orderId}`, body).then((_) => _.data);
