import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export const loadPatients = (params: Record<string, string>, paging: PagingRequest): Promise<any> =>
  httpClient.get('/patients', { params: { ...params, ...paging } }).then((_) => _.data);

export const getPatients = (projectId: string, paging: PagingRequest): Promise<any> =>
  httpClient.get('/patients', { params: { projectIds: projectId, ...paging } }).then((_) => _.data);

export const getPatient = (id: string): Promise<any> => httpClient.get(`/patients/${id}`).then((_) => _.data.results);

export type CreatePatientPayload = Record<string, string>;

export const createPatient = (data: CreatePatientPayload): Promise<any> =>
  httpClient.post('/patients', data).then((_) => _.data.results);

export type UpdatePatientPayload = Record<string, string>;

export const updatePatient = (id: string, data: UpdatePatientPayload): Promise<any> =>
  httpClient.patch(`/patients/${id}`, data).then((_) => _.data.results);

export const deletePatient = (id: string): Promise<any> =>
  httpClient.delete(`/patients/${id}`).then((_) => _.data.results);
