import { dateFormat } from 'utils/functions';
import { Patient, FormattedPatient } from 'utils/types';

// If the date is already in the YYYY-MM-DD format, (ISO 8601 Date format) simply return it
// Otherwise try parsing it
const formatDobIfNecessary = (dob: string): string => {
  if (/^\d{4}-\d{2}-\d{2}$/.test(dob)) {
    return dob;
  }
  // Perry note 5/4/23: 'Invalid Date' when ISO parse failure is exact same result as old version of dateFormat() fn
  return dateFormat(dob) || 'Invalid Date';
};

export const formatPatient = (patient: Patient): FormattedPatient => {
  const {
    id,
    dob,
    gender,
    assignedSex,
    email,
    firstName,
    lastName,
    phoneNumber,
    subjectId,
    createdAt,
    address1,
    address2,
    city,
    district1,
    postalCode,
    race,
    smsConsent,
  } = patient;

  return {
    id,
    email: email || '',
    firstName: firstName || '',
    lastName: lastName || '',
    phoneNumber: phoneNumber || '',
    subjectId: subjectId || '',
    dob: dob ? formatDobIfNecessary(dob) : '',
    gender: gender || '',
    assignedSex: assignedSex || '',
    createdAtTimestamp: new Date(createdAt).getTime(),
    // Perry note 5/4/23: 'Invalid Date' when ISO parse failure is exact same result as old version of dateFormat() fn
    createdAt: dateFormat(createdAt) || 'Invalid Date',
    address1: address1 || '',
    address2: address2 || '',
    city: city || '',
    state: district1 || '',
    zip: postalCode || '',
    race: race || '',
    smsConsent: smsConsent || '', // timestamp of consent was given, empty string otherwise
  };
};
