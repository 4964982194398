import { createTheme } from '@mui/material';

export const currentDesignTheme = createTheme({
  palette: {
    primary: { main: '#3f51b5', contrastText: '#fff' },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderColor: '#B3B3B3',
          color: '#000000',
          backgroundColor: '#F4F5F7',
          '&:hover': {
            borderColor: '#B3B3B3',
            backgroundColor: '#F4F5F7',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});
