import * as _I from './types';

export const inputComponentTypes = {
  EMAIL: 'email',
  PASSWORD: 'password',
  TEXT: 'text',
};

export const AWS_CHALLENGE_NAMES = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  MFA_SETUP: 'MFA_SETUP',
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA' as _I.AWSChallengeTypes,
};

export const ALERT_TYPES: Record<'DEFAULT' | 'ERROR' | 'WARNING' | 'INFO' | 'SUCCESS', _I.AlertTypes> = {
  DEFAULT: 'default',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};

export const errorText = {
  REQUIRED_FIELD: '* Required',
};

export const userInfo = {
  CODE: 'Code',
  CURRENT_PASSWORD: 'Current Pasword',
  EMAIL: 'Email',
  ENTER_CODE: 'Enter Passcode',
  KEEP_ME_SIGNED_IN: 'KEEP ME SIGNED IN',
  MFA: 'MFA',
  NEW_PASSWORD: 'New Password',
  OLD_PASSWORD: 'Old Password',
  PASSWORD: 'Password',
  USERNAME: 'Username',
};

export const colorTypes = {
  DEFAULT: 'default' as const,
};

export const inputTypes = {
  CHECKBOX: 'checkbox',
  PASSWORD: 'password',
  TEXT: 'text',
};

export const buttonText = {
  CONFIRM: 'Confirm',
  SIGN_IN: 'Sign In',
};

export const TASSO_STATUS_DIRECT_TO_PATIENT: Record<_I.DirectToPatientTassoStatus, string> = {
  atTasso: 'At Tasso',
  inTransitToPatient: 'In Transit To Patient',
  atPatient: 'At patient',
  inTransitToLab: 'In Transit To Lab',
  atLab: 'At Lab',
  resultsReady: 'Results Ready',
  replaced: 'Replaced',
};

export const TASSO_STATUS_AT_CUSTOMER_SITE: Record<_I.AtCustomerSiteTassoStatus, string> = {
  atTasso: 'At Tasso',
  awaitingPickup: 'Awaiting Pickup',
  atPatient: 'At patient',
  inTransitToLab: 'In Transit To Lab',
  atLab: 'At Lab',
  resultsReady: 'Results Ready',
  replaced: 'Replaced',
};

// Kept separate so they do now appear on patient pulldown shipping timeline
export const TASSO_ERROR_STATUS: Record<_I.ErrorTassoStatus, string> = {
  rejectedByLab: 'Rejected by Lab',
  returnToSender: 'Return to Sender',
  unknown: 'Unknown',
};

export const buttonTypes = {
  CANCEL: 'cancel',
  PRIMARY: 'primary',
  FILE_INPUT: 'browse',
};

export const GENDERS = {
  cisMale: 'cisMale',
  cisFemale: 'cisFemale',
  transgenderMale: 'transgenderMale',
  transgenderFemale: 'transgenderFemale',
  nonBinary: 'nonBinary',
  none: 'none',
  unspecified: 'unspecified',
  other: 'other',
  unknown: 'unknown',
  declinedToAnswer: 'declinedToAnswer',
};

export const ASSIGNED_SEXES = {
  male: 'male',
  female: 'female',
  unknown: 'unknown',
};

export const RACES = {
  american_indian_alaska_native: 'American Indian or Alaska Native',
  asian: 'Asian',
  black_african_american: 'Black or African American',
  native_hawaiian_other_pacific_islander: 'Native Hawaiian or Other Pacific Islander',
  hispanic_latino: 'Hispanic or Latino',
  white: 'White',
  other: 'Other',
  declined_to_answer: 'Declined To Answer',
};

export const ALLOWED_ROLES = {
  internalAdmin: 'Internal Admin',
  internalCustomerService: 'Internal Customer Service',
  customerAdmin: 'Customer Admin',
  customerOrganizer: 'Customer Organizer',
  customerSponsor: 'Customer Sponsor',
} as const;

/**
 * An order is considered "pending" until it reaches at least
 * the "readyToShip" status.
 * Status listed below all happen *before* readyToShip is set.
 * If the current status is "logisticsComplete" or beyond that,
 * it means that a device was already created.
 * The regular flow of status is:
 * 1) accepted (order was just created)
 * 2) readyForLogistics (pre device creation step)
 * 3) inLogistics (device is being created)
 * 4) logisticsComplete (device was created with status "createdForOrder")
 * 5) preparingForAssembly (run some checks post device creation)
 * 6) readyToShip (device is fully ready)
 *
 * "temporaryFailure" is set when a newly submitted order
 * fails to enter the order workflow (Step Functions) for a technical reason.
 * It gets retried a few times and eventually receives this status.
 * It's not completely dead but it couldn't start.
 * This status can be viewed as terminal, or it could mean
 * that manual intervention is required. Since we currently
 * don't display error statuses it makes sense to treat this
 * status as "pending" which will effectively put a device (order)
 * into a never ending pending state in the UI. It will require
 * users to request support from Tasso, but this is the way for now.
 */
export const PendingOrderStatuses = new Set([
  'temporaryFailure',
  'accepted',
  'readyForLogistics',
  'inLogistics',
  'preparingForAssembly',
]);

export const DISTRIBUTION_MODELS = {
  directToPatient: 'directToPatient',
  atCustomerSite: 'atCustomerSite',
} as const;

export const PROJECT_STATUSES = {
  open: 'open',
  archived: 'archived',
} as const;

export const DEVICE_TYPES = {
  M20: 'M20',
  SST: 'SST',
  tassoPlus: 'Tasso+',
} as const;
