import React, { createContext, useContext, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { ClickAwayListener, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';

import IdleLogoutWarningModal from 'components/IdleLogoutPromptModal';
import { useAuth } from 'providers/Auth';
import { USER_IDLE_TIMEOUT_MINUTES, USER_IDLE_TIMEOUT_WARNING_MINUTES } from 'utils/config';

// Automatically log the user out after this much time has passed. If number is 0, the check is disabled.
const userIdleTimeoutMs = Number(USER_IDLE_TIMEOUT_MINUTES || 15) * 60 * 1_000;

// This is the time BEFORE userIdleTimeoutMs at which the warning prompt will open. `useIdleTimer` requires that that
// this value (`promptBeforeIdle`) be less than userIdleTimeoutMs (`timeout`). As we want to be able to disable the
// idle logout feature (setting userIdleTimeoutMs to 0) for ease of local development, we force this to -1 to prevent
// potential configuration issues.
const userIdleTimeoutWarningMs =
  userIdleTimeoutMs === 0 ? -1 : Number(USER_IDLE_TIMEOUT_WARNING_MINUTES || 5) * 60 * 1_000;

// Limits how often the event handling logic for the IdleWarning and IdleTimeout event handlers can execute. The actual
// event listeners still respond to all events, but will only enter into the handling logic if at least this much time
// has lapsed since the last execution.
const userIdleEventHandlingThrottleMs = 5_000;

const idleLogoutSnackbarKey = 'idleLogoutSnackbar';

enum IdleStateMessages {
  CloseIdleWarning,
  UserSelectedLogout,
}

export type IdleLogoutProviderContextType = {
  logoutHandler: () => Promise<void>;
};

const IdleLogoutContext = createContext<IdleLogoutProviderContextType>({
  logoutHandler: async () => {},
});

const IdleLogoutProvider = (props: any) => {
  const [idlePromptOpen, setIdlePromptOpen] = useState<boolean>(false);
  const [idleLogoutAlertVisible, setIdleLogoutAlertVisible] = useState<boolean>(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { logout, profile } = useAuth();

  const onIdle = async () => {
    if (!profile) {
      return;
    }

    setIdlePromptOpen(false);
    await logout(null);
    setIdleLogoutAlertVisible(true);
  };

  // Message handler used to keep tab state in-sync. The idleTimer library itself handles keeping the timers in sync,
  // which means it will call `onIdle` across all tabs for us (opening the warning across all tabs). This handler is
  // used to perform any synchronization logic needed following user interaction (i.e., if they click on either of the
  // buttons in the warning modal).
  // Messages are passed by calling the `message(..)` method returned by `useIdleTimer()`. Here we've typed the input
  // to `IdleStateMessages`, but `message(..)` is capable of passing values of any type (including objects).
  const onMessage = (message: IdleStateMessages, idleTimer?: IIdleTimer) => {
    if (!idleTimer) {
      return;
    }

    if (!profile) {
      // If this tab isn't logged in, do nothing. (Prevents accidental logout prompting.)
      return;
    }

    switch (message) {
      case IdleStateMessages.CloseIdleWarning:
        setIdlePromptOpen(false);
        break;
      case IdleStateMessages.UserSelectedLogout:
        logoutHandler();
        setIdlePromptOpen(false);
        break;
      default:
        break;
    }
  };

  // useIdleTimer has a default set of events (https://idletimer.dev/docs/api/props#events) that it listens for.
  const {
    start: startIdleTimer,
    pause: pauseIdleTimer,
    message,
  } = useIdleTimer({
    onIdle,
    onMessage,
    onActive: () => setIdlePromptOpen(false),
    onPrompt: () => setIdlePromptOpen(true),
    timeout: userIdleTimeoutMs,
    promptBeforeIdle: userIdleTimeoutWarningMs,
    eventsThrottle: userIdleEventHandlingThrottleMs,
    startManually: true,
    stopOnIdle: true,
    crossTab: true,
    syncTimers: 500,
  });

  // Replaces the call done when a user clicks the "Logout" button in the portal for the purpose of
  // explicitly pausing the idle timer. This prevents unexpected behavior after being logged out.
  // If a user decides to log back in, the timer will be restarted by the `useEffect` watching
  // `profile` below.
  const logoutHandler = async () => {
    if (userIdleTimeoutMs !== 0) {
      pauseIdleTimer();
    }
    await logout('You successfully logged out');
  };

  const userSelectedLogoutHandler = async () => {
    // If a user opts to log out through the idle warning prompt, message any other tabs
    // for the portal that may be open to also log out.
    message(IdleStateMessages.UserSelectedLogout);
    await logoutHandler();
    setIdlePromptOpen(false);
  };

  useEffect(() => {
    if (profile && userIdleTimeoutMs !== 0) {
      startIdleTimer();
    }
  }, [profile, startIdleTimer]);

  useEffect(() => {
    if (idleLogoutAlertVisible) {
      enqueueSnackbar('For your security, you have been logged out due to inactivity.', {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
        key: idleLogoutSnackbarKey,
        persist: true,
        variant: 'info',
        onClose: () => {
          setIdleLogoutAlertVisible(false);
        },
        action: () => (
          <ClickAwayListener
            onClickAway={() => {
              setIdleLogoutAlertVisible(false);
            }}
          >
            <IconButton
              size="small"
              aria-label="Close"
              color="inherit"
              onClick={() => {
                setIdleLogoutAlertVisible(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </ClickAwayListener>
        ),
      });
    } else {
      closeSnackbar(idleLogoutSnackbarKey);
    }
  }, [idleLogoutAlertVisible, closeSnackbar, enqueueSnackbar]);

  return (
    <>
      <IdleLogoutContext.Provider
        value={{
          logoutHandler,
        }}
        {...props}
      />
      <IdleLogoutWarningModal
        visible={!!profile && idlePromptOpen}
        onStayLoggedIn={() => {
          message(IdleStateMessages.CloseIdleWarning);
          startIdleTimer();
          setIdlePromptOpen(false);
        }}
        onLogout={userSelectedLogoutHandler}
      />
    </>
  );
};

const useIdleLogout = (): IdleLogoutProviderContextType => useContext(IdleLogoutContext);

export { IdleLogoutProvider, useIdleLogout };
