import React, { createContext, useReducer, useRef } from 'react';

import { CancelTokenSource, makeCancelTokenSource } from 'services/httpClient';
import * as projectsApi from 'services/projects';
import { serializeApiErrors } from 'utils/errors';
import { loadAllPages, makeReducer } from 'utils/functions';
import { Project } from 'utils/types';

import { getInitialState, sortByProjectName } from './functions';
import * as types from './types';

const ProjectsContext = createContext<types.Context>({
  projects: [],
  project: null,
  loading: false,
  error: null,
  getProjects: async () => {},
  getProject: async () => {},
  resetProjects: () => {},
});

const ProjectsProvider = (props: any) => {
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const [state, setState] = useReducer(makeReducer<types.State>(), getInitialState());

  const getProjects: types.GetProjectsFunction = async (params, callback?) => {
    setState({ loading: true, error: null });

    const query = params.status ? { status: params.status } : {};

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      type PayloadType = Parameters<typeof projectsApi.getProjects>[0];
      const projects = await loadAllPages<Project, PayloadType>(
        projectsApi.getProjects,
        { query, cancelToken: cancelTokenSource.current.token },
        {
          pageLength: 100,
        }
      );

      setState({
        projects: projects.sort(sortByProjectName),
      });

      if (callback) {
        await callback(projects);
      }
    } catch (e) {
      cancelTokenSource.current = null;

      setState({
        error: serializeApiErrors(e),
      });
    } finally {
      setState({ loading: false });
    }
  };

  const getProject: types.GetProjectFunction = async (id: string, callback?) => {
    setState({
      loading: true,
      error: null,
    });

    try {
      cancelTokenSource.current = makeCancelTokenSource();

      const project: Project = await projectsApi.getProject(id, {
        cancelToken: cancelTokenSource.current.token,
      });

      setState({
        project,
      });

      if (callback) {
        await callback(project);
      }
    } catch (e) {
      cancelTokenSource.current = null;

      setState({
        error: serializeApiErrors(e),
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState({
        loading: false,
      });
    }
  };

  const resetProjects = (): void => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
      cancelTokenSource.current = null;
    }
    setState(getInitialState());
  };

  return (
    <ProjectsContext.Provider
      value={{
        projects: state.projects,
        project: state.project,
        loading: state.loading,
        error: state.error,
        getProjects,
        getProject,
        resetProjects,
      }}
      {...props}
    />
  );
};

const useProjects = (): types.Context => React.useContext(ProjectsContext);

export { ProjectsProvider, useProjects };
