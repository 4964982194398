import { CancelToken } from 'axios';

import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

interface GetProjectsConfig {
  query: Record<string, any>;
  cancelToken: CancelToken;
}

export const getProjects = (config: GetProjectsConfig, paging: PagingRequest): Promise<any> => {
  return httpClient
    .get('/projects', {
      params: { ...config.query, ...paging },
      cancelToken: config.cancelToken,
    })
    .then((_) => _.data);
};

interface GetProjectConfig {
  cancelToken: CancelToken;
}

export const getProject = (id: string, config: GetProjectConfig): Promise<any> => {
  return httpClient.get(`/projects/${id}`, { cancelToken: config.cancelToken }).then((_) => _.data.results);
};
